import config from "config";
// import whatsapp from "assets/img/payment-method/carlton-whatsapp.png";
// import Epayme from "assets/img/payment-method/epayme.png";
// import AsiaBanks from "assets/img/payment-method/asiabanks.png";
// import epayme from "assets/img/payment-method/epayme.png";
import usdt from "assets/images/payment-method/USDT.png";
// import { INT_COUNTRIES } from "helpers/countries";

export const allowedMethods = [
  // {
  //   gateway: "WIRE_TRANSFER",
  //   name: "Wire Transfer",
  //   image: "/img/payment-method/wire-transfer.png",
  //   allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  //   receipt: true,
  // },
  // {
  //   gateway: "OLX_FOREX",
  //   name: "Olx Forex",
  //   image: "/img/payment-method/olxforex.jpeg",
  //   allowed: ["fiatDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "CRYPTO",
  //   name: "Crypto",
  //   image: "/img/payment-method/crypto.png",
  //   allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit"] : [],
  //   receipt: true,
  // },
  // {
  //   gateway: "EPAYME",
  //   name: "ePayMe",
  //   image: epayme,
  //   allowed: ["mt5Deposit", "fiatDeposit"],
  //   receipt: false,
  //   // isSandbox: true
  // },
  {
    gateway: "FINITIC_PAY",
    name: "Finitic Pay",
    image: usdt,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: false,
    isSandbox: true,
  },
];